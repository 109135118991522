import '../../App.css';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FaInfoCircle } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';

@inject('store')
@observer
class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.store.setInfoModal(false);
  }

  render() {
    const { store } = this.props;
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={store.showInfoModal}
        onHide={() => this.handleClose()}
        id="infoModal"
      >
        <Modal.Body>
          <div className="login-form-space">
            <h2>
              <FaInfoCircle /> About
            </h2>
            <hr className="small-underline" />
            <p className="info-text">DSD Design Configurator designed by DSD</p>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default LoginModal;
