import '../../App.css';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FaLink, FaMinusCircle } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { VIEW_MODEL, HOST_URL } from '../../Constants';

const CardList = (props) => {
  const handleShare = () => {
    props.shareFunc();
  };

  const handleRemove = (item) => () => {
    props.removeFunc(item.id || item._id);
  };

  const handleClick = (item) => (event) => {
    props.clickFunc(item, event);
  };

  return (
    <div className="save-file-container">
      {props.fileList.map((item, iterator) => (
        <div className="save-file-list-block" key={iterator}>
          <div
            floor-model-url={item.url}
            file-name={item.name}
            email={item.email}
            _id={item._id || item.id}
            onClick={handleClick(item)}
            className="save-file-list-item"
          >
            <div className="save-file-name">{item.name}</div>
            <div>
              <CopyToClipboard text={HOST_URL + VIEW_MODEL + item.url} onCopy={handleShare}>
                <Button variant="light">
                  <FaLink />
                </Button>
              </CopyToClipboard>
              <Button _id={item._id || item.id} onClick={handleRemove(item)} variant="light">
                <FaMinusCircle />
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardList;
