// OLD SETUP
// export const HOST_URL = 'https://www.feldsparhomes.com/';
// export const BASE_URL = "https://www.feldsparhomes.com:8001/";
// export const BASE_URL_AUTH = 'https://www.feldsparhomes.com:8000/';
// export const DEFAULT_WALL_MAP = '5f0dde8ee5148d0ef82ff069';
// export const DEFAULT_FLOOR_MAP = '5f0dddf4e5148d0ef82ff066';

// NEW SETUP
export const ENVIRONMENT = process.env.REACT_APP_ENV || 'dev';
export const HOST_URL = process.env.REACT_APP_HOST_URL || 'http://localhost:3000/';
export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:8001/';
export const BASE_URL_AUTH = process.env.REACT_APP_BASE_URL_AUTH || 'http://localhost:8001/';
export const DEFAULT_WALL_MAP = '/rooms/textures/wallmap.png';
export const DEFAULT_FLOOR_MAP = '/rooms/textures/hardwood.png';

// COMMON-----------------------------------------------------------
export const ASSETS = 'assets/';
export const MODELS = 'models/';
export const FLOOR_MODELS = 'floorModel/';
export const TEXTURES = 'textures/';
export const RETRIEVE = 'retrieve/';
export const USERS = 'users/';
export const RESOURCES = 'resources/';
export const FIND = 'find/';
export const VIEW_MODEL = 'view/';

export const REGISTER = 'register';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const UPLOAD_ASSET = 'uploadAsset';
export const UPDATE_ASSET_ENTRY = 'updateAssetEntry';

export const SOFA_CATEGORY = 'sofa';
export const CHAIR_CATEGORY = 'chair';
export const BED_CATEGORY = 'bed';
export const ARCH_CATEGORY = 'architectural';
export const MISC_CATEGORY = 'misc';
export const KITCHEN_CATEGORY = 'kitchen';
export const RUG_CATEGORY = 'rug';
export const TABLE_CATEGORY = 'table';
export const DESK_CATEGORY = 'desk';
export const CABINET_CATEGORY = 'cabinet';
export const CURTAIN_CATEGORY = 'curtain';
export const LIGHT_CATEGORY = 'light';
export const BATH_CATEGORY = 'bath';
export const FLOOR_CATEGORY = 'floor';
export const FLOOR_WOOD_CATEGORY = 'floorWood';
export const FLOOR_MARBLE_CATEGORY = 'floorMarble';
export const FLOOR_TILE_CATEGORY = 'floorTile';
export const WALL_CATEGORY = 'wall';
export const WALL_SOLID_CATEGORY = 'solid';
export const WALL_TILE_CATEGORY = 'wallTile';
export const GET_FREE_RESOURCES = 'getFreeResources';
export const GET_RESOURCES = 'getResources';

export const REMOVE_MODEL = 'removeModel';
export const RETRIEVE_MODEL = 'retrieve';
export const UPDATE_MODEL = 'update';
