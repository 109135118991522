import '../../../App.css';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';
import ModalHeading from '../../ModalHeading.jsx';
import SaveFileList from '../SaveFileList.jsx';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { BASE_URL, FLOOR_MODELS, REMOVE_MODEL, RETRIEVE_MODEL, UPLOAD_ASSET } from '../../../Constants';
import LargeAlert from '../../LargeAlert.jsx';
import $ from 'jquery';

@inject('store')
@observer
class SaveModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      isError: false,
      fileName: '',
      fileNameTemp: '',
      fileUri: '',
      id: '',
      stateBlob: {},
      saveFileList: [],
      showModal: false,
      copied: false,
      overwrite: 'Save',
    };

    this.handleClose = this.handleClose.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.updateSaveFileList = this.updateSaveFileList.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleShare = this.handleShare.bind(this);
  }

  handleClose() {
    this.props.store.setSaveFileModal(false);
    this.setState({
      fileName: '',
      fileUri: '',
      id: '',
      showModal: false,
      isError: false,
    });
  }

  handleShare() {
    this.setState({ copied: true, isError: false });
  }

  async handleRemove(id) {
    let token = this.props.store.getAccessToken;
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let body = { _id: id };
    try {
      await axios.post(BASE_URL + FLOOR_MODELS + REMOVE_MODEL, body, config);
      // this.updateSaveFileList();
      const _newList = this.state.saveFileList.filter((item) => item.id !== id);
      this.setState({ saveFileList: _newList, isError: false });
    } catch (e) {
      console.log(e);
      this.setState({ isError: true });
    }
  }

  handleClick(item, event) {
    this.setState({
      fileName: item.name,
      fileNameTemp: item.name,
      fileUri: item.url,
      isError: false,
      overwrite: 'Overwrite',
      id: item.id,
    });
    $('.save-file-list-item').css('border-color', '#ffffff');
    event.currentTarget.style.borderColor = '#5a6268';
  }

  handleInputChange(event) {
    $('.save-file-list-item').css('border-color', '#ffffff');
    this.setState({
      id: '',
      fileName: event.target.value,
      fileNameTemp: event.target.value,
      isError: false,
      overwrite: 'Save',
    });
  }

  async updateSaveFileList() {
    let token = this.props.store.getAccessToken;
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      let res = await axios.post(BASE_URL + FLOOR_MODELS + RETRIEVE_MODEL, {}, config);
      this.setState({ saveFileList: res.data, isError: false });
    } catch (e) {
      console.log(e);
      this.setState({ isError: true });
    }
  }

  async uploadFile(blob) {
    if (!this.state.isDisabled && this.state.fileName !== '') {
      const data = new FormData();
      data.append('file', blob);
      let token = this.props.store.getAccessToken;
      let config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      try {
        if (this.state.id !== '') {
          data.append('id', this.state.id);
        }
        data.append('fileType', 'floorModel');
        data.append('extension', 'blueprint3d');
        data.append('name', this.state.fileName);
        await axios.post(BASE_URL + UPLOAD_ASSET, data, config);
        this.setState({ fileName: '', id: '', fileNameTemp: '', isDisabled: false, isError: false });
        this.updateSaveFileList();
        this.handleClose();
      } catch (e) {
        console.log(e);
        this.setState({ isDisabled: false, isError: true });
      }
    } else {
      this.setState({ isDisabled: false, isError: false });
    }
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (
      prevState.stateBlob !== this.props.blob &&
      this.props.blob !== null &&
      this.props.blob !== '' &&
      this.state.isDisabled === false
    ) {
      this.setState({
        stateBlob: this.props.blob,
        isDisabled: true,
        isError: false,
        overwrite: 'Save',
      });
      this.uploadFile(this.props.blob);
    }

    if (this.props.store.showSaveFileModal && !prevState.showModal) {
      this.setState({ showModal: true, isError: false, overwrite: 'Save' });
      this.updateSaveFileList();
    }
  }

  render() {
    const { store } = this.props;
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={store.showSaveFileModal}
        onHide={() => this.handleClose()}
        id="saveModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <ModalHeading icon="saveFile" message="Save Project" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="login-form-space">
            <SaveFileList
              clickFunc={this.handleClick}
              removeFunc={this.handleRemove}
              shareFunc={this.handleShare}
              fileList={this.state.saveFileList}
            />

            <div className="save-file-space">
              <div className="save-file-text">Save As: </div>
              <input
                type="text"
                name="name"
                value={this.state.fileNameTemp}
                className="save-file-input"
                onChange={this.handleInputChange}
              />
            </div>

            <Button
              variant="danger"
              className="login-submit-button"
              disabled={this.state.isDisabled}
              onClick={this.props.clickFunc}
            >
              {this.state.overwrite}
            </Button>
            {this.state.copied && <LargeAlert variant="success" message="Link Copied to Clipboard" />}
            {this.state.isError && <LargeAlert variant="danger" message="Some Error Occurred" />}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default SaveModal;
